<template>
  <body class="registration_wraapper">

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <section class="user_login h-100">
          <div class="row no-gutters align-items-center h-100">
            <div class="col-xl-4 offset-xl-7 col-lg-6 offset-lg-6 col-md-12 add-newclass">
              <h1>Sign up</h1>
              <p>Every adventure starts with an intro.</p>
              <ValidationObserver ref="simpleRules">
                <form id="signin-form" v-on:submit.prevent="validationForm">
                  <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="form-group">
                        <label>First Name</label>
                        <validation-provider name="First Name" rules="required|max:20" #default="{ errors }">
                          <input type="text" v-model="firstname" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-lg">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-md-6  col-sm-6 col-6">
                      <div class="form-group">
                        <label>Last Name</label>
                        <validation-provider name="Last Name" rules="required|max:20" #default="{ errors }">
                          <input type="text" v-model="lastname" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-lg">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Email Address</label>
                        <validation-provider name="Email Address" rules="required|max:50|email|unique"
                          #default="{ errors }">
                          <input type="email" v-model="email" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-lg">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-md-6  col-sm-6 col-6">
                      <div class="form-group">
                        <label>Password</label>
                        <validation-provider name="Password" rules="required|password|min:5|max:20" #default="{ errors }"
                          vid="password">
                          <input type="password" v-model="password" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-lg">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-md-6  col-sm-6 col-6">
                      <div class="form-group">
                        <label>Confirm Password</label>
                        <validation-provider name="Confirm Password" rules="required|confirmed:password|min:8"
                          #default="{ errors }">
                          <input type="password" v-model="cpassword"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" class="form-control" value="">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <b-form-checkbox id="checkbox" v-model="status" name="checkbox" value="1" unchecked-value="0">
                    <p class="terms_conditions">I accept the terms and use? <router-link to="/terms-and-conditions">Terms
                        & Conditions</router-link></p>
                  </b-form-checkbox>
                  <p class="text-center mt-4"><button class="btn btn-primary ">Register</button></p>
                  <p class="text-center">Already have an account? <router-link to="/signin">Sign in </router-link>
                  </p>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </section>
      </div>
    </div>

  </body>
</template>

<script>
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, unique
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BFormCheckbox
} from 'bootstrap-vue'
import firebase from "firebase";
import store from '@/store'
import { db } from '@/main'
import axios from 'axios'
export default {
  name: 'Registration',
  components: {
    ValidationProvider,
    ValidationObserver,
    validate,
    BFormCheckbox,
    required,
    email,
    confirmed,
    password,
    unique
  },

  data: function () {
    return {
      title: "Sign-up",
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      cpassword: '',
      status: '',

    }
  },

  created: function () {
    document.title = this.title;
    document.getElementById("google_translate_element").style.display = "none";
  },

  methods: {
    validationForm() {

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },

    submit: function () {
      //alert('Registered Successfully');
      // localStorage.clear();
      store.dispatch('app/commitActivateLoader');

      if (this.status == true) {
        this.status = 1;
      } else if (this.status == false) {
        this.status = 0;
      }

      if (this.status == 1) {
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then(data => {

            data.user.updateProfile({
              displayName: this.firstname
            })
              .then(() => {
                var date = new Date().toLocaleString();
                //.toISOString().slice(0, 10);

                db.collection("users")
                  .add({
                    user_id: data.user.uid,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    password: this.password,
                    checkname: this.status,
                    image: "",
                    status: 2,
                    country: '',
                    nationality: '',
                    forgot_otp: '',
                    otp_update: '',
                    image: '',
                    created: date,
                    modified: date,
                  })

                  .then((docRef) => {
                    localStorage.setItem("Ustatus", 2);
                    let userData =
                    {
                      id: docRef.id,
                      fullName: this.firstname,
                      username: this.firstname + '' + this.lastname,
                      avatar: "",
                      email: this.email,
                      ability:
                        [{
                          action: "manage",
                          subject: "all"
                        }],
                      "extras": { "eCommerceCartItemsCount": 5 }
                    }
                    localStorage.setItem('userData', JSON.stringify(userData))
                    this.$ability.update(userData.ability)
                    localStorage.setItem("Ustatus", 2);
                    console.log("Added Successfully!");
                    localStorage.setItem("userSessionId", docRef.id);
                    localStorage.setItem("userFirstname", this.firstname);
                    this.$router.replace({ name: "userProfile" });
                    this.showNotification('User Added Successfully!', 'UserIcon', 'success');
                    //cart start
                    var uid = docRef.id;
                    var randomId = localStorage.getItem("randomId");

                    this.random_id = randomId;
                    db.collection("cart")
                      .where("random_id", "==", this.random_id)
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                          console.log(uid);

                          db.collection("cart")
                            .doc(doc.id)
                            .update({
                              uid: uid,
                            })
                            .then(() => {
                              console.log("Document successfully updated!");
                            })
                            .catch((error) => {
                              store.dispatch('app/commitDeactivateLoader');

                              // The document probably doesn't exist.
                              console.error("Error updating document: ", error);
                            });
                        });
                      });
                    //cart end

                    store.dispatch('app/commitDeactivateLoader');
                  })
                  .then(() => {
                    // const url = "https://introsbs.com/api/registrationthankyou";
                    const url = this.$VUE_APP_API_ENDPOINT + "registrationthankyou";

                    const data1 = {
                      email: this.email,
                      name: this.firstname,

                    };

                    const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/json' },
                      data: JSON.stringify(data1),
                      url: url
                    };

                    axios(options).then(
                      (response) => {
                        console.warn(Object.keys(response));
                        if (response != "") {
                          // this.$toasted.success('Success, Registration Successfully!', {
                          //   icon: 'check',
                          // })

                          // this.$router.push("signin");
                        }
                      }, () => {
                        this.isLoading = false;
                        // this.$toasted.error('Error,Registration Faild !', {
                        //   icon: 'check',
                        //   //icon : 'error_outline'
                        // })
                      });
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);
                    store.dispatch('app/commitDeactivateLoader');
                    this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                  });
                var id = localStorage.getItem("userSessionId");
                // console.log('rrr ' + id);
                // store.dispatch('app/commitDeactivateLoader');

                //this.$router.replace({ name: "Dashboard" });
                //this.$router.replace({ name: "userProfile" });
                //this.$router.push('/');

              });
          }).catch(err => {
            // console.log('sdd');
            console.log(err.message);
            store.dispatch('app/commitDeactivateLoader');
            this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
            // this.$toasted.error('Error,' + err.message, {
            //   //icon : 'check',
            //   icon: 'error_outline'
            // })

            this.error = err.message;
          });
      } else {
        store.dispatch('app/commitDeactivateLoader');
        alert("Please Accept Terms And Condition!");
      }
    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  }
}
</script>

<style scoped>
.user_login {
  height: 86vh;
}

.user_login h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.user_login form {
  margin-top: 50px;
}

.user_login .form-control {
  background: #f2f4f5 !IMPORTANT;
  height: 40px;
  border-color: #f2f4f5;
}

.user_login .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dadcdc;
  outline: 0;
}

.user_login label {
  display: block;
}

.user_login .row.h-100 {
  position: relative;
}

.user_login .row.h-100:before {
  background: url(../../assets/front/image/sign_in.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
}

.frames {
  position: absolute;
  height: 100vh;
}

.frames .bottom_frame {
  position: absolute;
  bottom: 0;
}

.user_login .row.h-100 .col-xl-4 {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_login .row.h-100 .col-xl-4::-webkit-scrollbar,
.frame:before,
.frame:after {
  display: none;
}

.frames:before,
.frames:after {
  position: absolute;
  content: "";
  width: 3%;
  height: 40%;
  left: 0;
  background: url(../../assets/front/image/mobile_frame5.png);
  top: 50%;
  transform: translateY(-50%);
  background-size: cover;
}

.frames:after {
  right: 0;
  left: auto;
  background: url(../../assets/front/image/mobile_frame6.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.site-wrapper {
  height: 100vh;
  border: none;
  border-radius: 0;
}

body {
  background: #fff;
}

.user_login {
  margin-top: 0;
}





.registration_wraapper::after,
.registration_wraapper::before {
  position: absolute;
  content: "";
  z-index: 9;
  transform: translateX(-50%);
}

.registration_wraapper::before {
  width: 120px;
  height: 10px;
  background: #fff;
  top: 32px;
  left: 50%;
  border-radius: 20px;
  opacity: .5;
}

.registration_wraapper::after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 29px;
  left: calc(50% - 90px);
  background: #898b8d;
}

.site-wrapper {
  margin: 0 auto;
  position: relative;
  border: 25px solid #14171b;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-radius: 119px;
  border-top-width: 72px;
  z-index: 8;
  border-bottom-width: 72px;
}





@media only screen and (max-width: 992px) {

  .frames,
  .user_login .row.h-100:before {
    display: none;
  }

  .user_login {
    height: 100vh;
    margin-top: 0;
  }

  .user_login>.row {
    background: none;
    padding: 0px 15px;
  }
}

@media only screen and (max-Width: 600px) {
  .user_login .row.h-100 .col-xl-4 {
    justify-content: flex-start;
  }

  /* .site-wrapper.overflow-hidden {
    border: none;
  } */

  .registration_wraapper[data-v-72666184]::after,
  .registration_wraapper[data-v-72666184]::before {
    display: none;
  }
}

.terms_conditions {
  font-size: 15px;
}

.frame>.site-wrapper.overflow-hidden {
  height: 100vh !important;
}
</style>