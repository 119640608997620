var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('body',{staticClass:"registration_wraapper"},[_c('div',{staticClass:"frame"},[_c('div',{staticClass:"site-wrapper overflow-hidden"},[_c('section',{staticClass:"user_login h-100"},[_c('div',{staticClass:"row no-gutters align-items-center h-100"},[_c('div',{staticClass:"col-xl-4 offset-xl-7 col-lg-6 offset-lg-6 col-md-12 add-newclass"},[_c('h1',[_vm._v("Sign up")]),_c('p',[_vm._v("Every adventure starts with an intro.")]),_c('ValidationObserver',{ref:"simpleRules"},[_c('form',{attrs:{"id":"signin-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-6 col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("First Name")]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],staticClass:"form-control form-control-lg",class:errors.length > 0 ? 'is-invalid' : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6  col-sm-6 col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],staticClass:"form-control form-control-lg",class:errors.length > 0 ? 'is-invalid' : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email Address")]),_c('validation-provider',{attrs:{"name":"Email Address","rules":"required|max:50|email|unique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control form-control-lg",class:errors.length > 0 ? 'is-invalid' : 'is-valid',attrs:{"type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6  col-sm-6 col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('validation-provider',{attrs:{"name":"Password","rules":"required|password|min:5|max:20","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control form-control-lg",class:errors.length > 0 ? 'is-invalid' : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-6  col-sm-6 col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirm Password")]),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cpassword),expression:"cpassword"}],staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : 'is-valid',attrs:{"type":"password","value":""},domProps:{"value":(_vm.cpassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cpassword=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('b-form-checkbox',{attrs:{"id":"checkbox","name":"checkbox","value":"1","unchecked-value":"0"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('p',{staticClass:"terms_conditions"},[_vm._v("I accept the terms and use? "),_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v("Terms & Conditions")])],1)]),_c('p',{staticClass:"text-center mt-4"},[_c('button',{staticClass:"btn btn-primary "},[_vm._v("Register")])]),_c('p',{staticClass:"text-center"},[_vm._v("Already have an account? "),_c('router-link',{attrs:{"to":"/signin"}},[_vm._v("Sign in ")])],1)],1)])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }